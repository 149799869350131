"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ActionType;
(function (ActionType) {
    ActionType["APP"] = "APP::PRINT::APP";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
var Action;
(function (Action) {
    Action["APP"] = "APP";
})(Action = exports.Action || (exports.Action = {}));
