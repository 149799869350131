"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var helper_1 = require("../helper");
var types_1 = require("../types");
var types_2 = require("./types");
function clickButton(group, component, payload) {
    var id = component.id;
    var action = helper_1.getEventNameSpace(group, types_2.Action.CLICK, component);
    var buttonPayload = {
        id: id,
        payload: payload,
    };
    return helper_1.actionWrapper({ type: action, group: group, payload: buttonPayload });
}
exports.clickButton = clickButton;
function update(group, component, props) {
    var id = component.id;
    var label = props.label;
    var action = helper_1.getEventNameSpace(group, types_2.Action.UPDATE, component);
    var buttonPayload = __assign({ id: id,
        label: label }, props);
    return helper_1.actionWrapper({ type: action, group: group, payload: buttonPayload });
}
exports.update = update;
function isValidButtonProps(button) {
    return typeof button.id === 'string' && typeof button.label === 'string';
}
exports.isValidButtonProps = isValidButtonProps;
var Button = /** @class */ (function (_super) {
    __extends(Button, _super);
    function Button(app, options) {
        var _this = _super.call(this, app, types_1.ComponentType.Button, types_1.Group.Button) || this;
        _this.disabled = false;
        _this.set(options, false);
        return _this;
    }
    Object.defineProperty(Button.prototype, "options", {
        get: function () {
            return {
                disabled: this.disabled,
                icon: this.icon,
                label: this.label,
                style: this.style,
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "payload", {
        get: function () {
            return __assign({ id: this.id }, this.options);
        },
        enumerable: true,
        configurable: true
    });
    Button.prototype.set = function (options, shouldUpdate) {
        if (shouldUpdate === void 0) { shouldUpdate = true; }
        var mergedOptions = helper_1.getMergedProps(this.options, options);
        var label = mergedOptions.label, disabled = mergedOptions.disabled, icon = mergedOptions.icon, style = mergedOptions.style;
        this.label = label;
        this.disabled = !!disabled;
        this.icon = icon;
        this.style = style;
        if (shouldUpdate) {
            this.dispatch(types_2.Action.UPDATE);
        }
        return this;
    };
    Button.prototype.dispatch = function (action, payload) {
        switch (action) {
            case types_2.Action.CLICK:
                this.app.dispatch(clickButton(this.group, this.component, payload));
                break;
            case types_2.Action.UPDATE:
                var updateAction = update(this.group, this.component, this.payload);
                this.app.dispatch(updateAction);
                break;
        }
        return this;
    };
    return Button;
}(helper_1.ActionSet));
exports.Button = Button;
function create(app, options) {
    return new Button(app, options);
}
exports.create = create;
