"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var type_validate_1 = require("./type-validate");
function createActionValidator(type, payloadSchema, payloadRequired, idRequired) {
    if (payloadSchema === void 0) { payloadSchema = undefined; }
    if (payloadRequired === void 0) { payloadRequired = false; }
    if (idRequired === void 0) { idRequired = false; }
    var idSchema = type_validate_1.matchesObject({
        id: idRequired ? type_validate_1.matchesString() : type_validate_1.makeOptional(type_validate_1.matchesString()),
    });
    var schema = payloadSchema ? type_validate_1.composeSchemas(idSchema, payloadSchema) : idSchema;
    return type_validate_1.matchesObject({
        type: type_validate_1.matchesEnum(type, {
            message: function (_, val) { return "The action type `" + val + "` is invalid or unsupported"; },
        }),
        payload: payloadRequired ? schema : type_validate_1.makeOptional(schema),
    });
}
exports.createActionValidator = createActionValidator;
function actionMessage(errors) {
    return errors
        .map(function (err) {
        var path = err.path, error = err.error, message = err.message, value = err.value;
        var valueStr = typeof value === 'object' ? JSON.stringify(value) : value;
        return "`" + error + "` thrown for" + (path ? " path: " + path + " and" : '') + " value: `" + valueStr + "`" + (message ? " with message: " + message : '');
    })
        .join(' | ');
}
exports.actionMessage = actionMessage;
function isValidRelativePath(path) {
    return typeof path === 'string' && (path === '' || path.startsWith('/'));
}
exports.isValidRelativePath = isValidRelativePath;
exports.relativeUrlSchema = type_validate_1.composeSchemas(type_validate_1.matchesString(), function (value) {
    return isValidRelativePath(value)
        ? undefined
        : [{ error: 'invalid_relative_path', value: value, message: 'expected string to start with `/`' }];
});
exports.relativePathSchema = type_validate_1.matchesObject({
    path: exports.relativeUrlSchema,
});
function mixedAppClientCheck(window) {
    window.addEventListener('DOMContentLoaded', function () {
        if (!window.hasOwnProperty('ShopifyApp')) {
            return;
        }
        console.error("%cException Detected \uD83D\uDEAB\n\n%cAn instance of the EASDK client was detected while initializing Shopify App Bridge. Using Shopify App Bridge and the EASDK simultaneously is not supported.\n\nIf you're migrating an existing app that was built with the shopify_app gem, then the EASDK client might have been included in the home page view template. In this case, remove it from your app before initializing Shopify App Bridge again.", 'font-size: large;', 'font-size: normal;');
    }, { once: true });
}
exports.mixedAppClientCheck = mixedAppClientCheck;
