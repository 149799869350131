"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var helper_1 = require("../../helper");
var types_1 = require("../../types");
var types_2 = require("./types");
function push(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.Navigation,
        type: types_2.ActionType.PUSH,
    });
}
exports.push = push;
function replace(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.Navigation,
        type: types_2.ActionType.REPLACE,
    });
}
exports.replace = replace;
var History = /** @class */ (function (_super) {
    __extends(History, _super);
    function History(app) {
        return _super.call(this, app, 'History', types_1.Group.Navigation) || this;
    }
    Object.defineProperty(History.prototype, "payload", {
        get: function () {
            return { id: this.id };
        },
        enumerable: true,
        configurable: true
    });
    History.prototype.dispatch = function (type, path) {
        var payload = __assign({}, this.payload, { path: path });
        switch (type) {
            case types_2.Action.PUSH:
                this.app.dispatch(push(payload));
                break;
            case types_2.Action.REPLACE:
                this.app.dispatch(replace(payload));
                break;
        }
        return this;
    };
    return History;
}(helper_1.ActionSet));
exports.History = History;
function create(app) {
    return new History(app);
}
exports.create = create;
