"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
var button_1 = require("./button");
var Modal_1 = require("../../actions/Modal");
exports.ActionType = Modal_1.ActionType;
var Button_1 = require("../../actions/Button");
var safe_redirect_1 = require("../safe-redirect");
function matchesSafeOrigin(value, localOrigin) {
    var hostName;
    try {
        hostName = new URL(localOrigin).hostname;
    }
    catch (error) {
        return [
            {
                error: 'invalid_app_origin',
                value: localOrigin,
                message: "Provided value for app origin: `" + localOrigin + "` is invalid",
            },
        ];
    }
    var isSafeSrc = safe_redirect_1.isSafe(value, {
        requireAbsolute: true,
        requireSSL: true,
        whitelist: [hostName],
    });
    if (!isSafeSrc) {
        return [
            {
                error: 'unsafe_origin',
                value: value,
                message: "Provided value is not whitelisted",
            },
        ];
    }
}
function matchesSize() {
    return function (value) {
        var values = [Modal_1.Size.Small, Modal_1.Size.Medium, Modal_1.Size.Large];
        if (values.includes(value)) {
            return;
        }
        var message = "expected:" + values.map(function (val) { return "`" + val + "`"; }).join(' or ');
        if (value === Modal_1.Size.Full) {
            message += ". Size `" + value + "` is deprecated as of version 1.6.5 and will fall back to size `medium`";
        }
        if (value === Modal_1.Size.Auto) {
            message += ". Size `" + value + "` is deprecated as of version 1.12.x and will fall back to size `medium`. Use the `setUpModalAutoSizing` utility from `app-bridge-utils` instead";
        }
        return [
            {
                error: 'invalid_enum_value',
                value: value,
                message: message,
            },
        ];
    };
}
function getModalSchema(props, localOrigin) {
    if (props === void 0) { props = {}; }
    var baseModalSchema = type_validate_1.matchesObject({
        title: type_validate_1.makeOptional(type_validate_1.matchesString()),
        footer: type_validate_1.makeOptional(type_validate_1.matchesObject({
            buttons: type_validate_1.matchesObject({
                primary: type_validate_1.makeOptional(button_1.buttonSchemaWithId),
                secondary: type_validate_1.makeOptional(type_validate_1.matchesArray(button_1.buttonSchemaWithId)),
            }),
        })),
        size: type_validate_1.makeOptional(matchesSize()),
    });
    if (Modal_1.isIframeModal(props)) {
        if (props.url) {
            var urlSchema = type_validate_1.matchesObject({
                url: type_validate_1.composeSchemas(type_validate_1.matchesString(), function (value) {
                    return value.startsWith('https://')
                        ? undefined
                        : [
                            {
                                error: 'invalid_secure_url',
                                value: value,
                                message: 'expected string to start with `https://`',
                            },
                        ];
                }, function (value) { return (localOrigin ? matchesSafeOrigin(value, localOrigin) : undefined); }),
            });
            return type_validate_1.composeSchemas(baseModalSchema, urlSchema);
        }
        return type_validate_1.composeSchemas(baseModalSchema, utils_1.relativePathSchema);
    }
    return type_validate_1.composeSchemas(baseModalSchema, type_validate_1.matchesObject({ message: type_validate_1.matchesString() }));
}
function validateProps(props, localOrigin) {
    return type_validate_1.validate(props, getModalSchema(props, localOrigin));
}
exports.validateProps = validateProps;
function validateAction(action, localOrigin) {
    var schema = getModalSchema(action.payload, localOrigin);
    switch (action.type) {
        case Modal_1.ActionType.OPEN:
        case Modal_1.ActionType.UPDATE:
            return type_validate_1.validate(action, utils_1.createActionValidator(Modal_1.ActionType, schema, true, action.type === Modal_1.ActionType.UPDATE));
        case Modal_1.ActionType.FOOTER_BUTTON_CLICK:
            return button_1.validateAction(__assign({}, action, { type: Button_1.ActionType.CLICK }));
        case Modal_1.ActionType.FOOTER_BUTTON_UPDATE:
            return button_1.validateAction(__assign({}, action, { type: Button_1.ActionType.UPDATE }));
        case Modal_1.ActionType.CLOSE:
        default:
            return type_validate_1.validate(action, utils_1.createActionValidator(Modal_1.ActionType));
    }
}
exports.validateAction = validateAction;
