"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Fullscreen_1 = require("../../actions/Fullscreen");
exports.ActionType = Fullscreen_1.ActionType;
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
/**
 * Performs validation for an fullscreen action
 * @param action the action being dispatched
 * @returns undefined or an array of validation errors
 *
 * @beta
 */
function validateAction(action) {
    var validator = utils_1.createActionValidator(Fullscreen_1.ActionType);
    return type_validate_1.validate(action, validator);
}
exports.validateAction = validateAction;
