"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// tslint:disable-next-line:no-empty
function noop() { }
exports.noop = noop;
function createUniqueIDFactory(prefix) {
    var index = 1;
    return function () { return "" + prefix + index++; };
}
exports.createUniqueIDFactory = createUniqueIDFactory;
