"use strict";
function __export(m) {
    for (var p in m) if (!exports.hasOwnProperty(p)) exports[p] = m[p];
}
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var env_1 = require("./util/env");
var actions = __importStar(require("./actions"));
exports.actions = actions;
var createApp = env_1.isDevelopment
    ? require('./development').default
    : require('./production').default;
exports.default = createApp;
__export(require("./MessageTransport"));
__export(require("./client"));
