"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var type_validate_1 = require("../type-validate");
var ContextualSaveBar_1 = require("../../actions/ContextualSaveBar");
exports.ActionType = ContextualSaveBar_1.ActionType;
var utils_1 = require("../utils");
exports.contextSaveBarSchema = type_validate_1.makeOptional(type_validate_1.matchesObject({
    discardAction: type_validate_1.makeOptional(type_validate_1.matchesObject({
        disabled: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
        discardConfirmationModal: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
    })),
    saveAction: type_validate_1.makeOptional(type_validate_1.matchesObject({
        disabled: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
    })),
}));
function validateProps(props) {
    return type_validate_1.validate(props, exports.contextSaveBarSchema);
}
exports.validateProps = validateProps;
function validateAction(action) {
    var validator = utils_1.createActionValidator(ContextualSaveBar_1.ActionType, exports.contextSaveBarSchema);
    return type_validate_1.validate(action, validator);
}
exports.validateAction = validateAction;
