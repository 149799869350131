"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dom_1 = require("./dom");
exports.FOCUSABLE_SELECTOR = 'a,frame,iframe,input:not([type=hidden]):not(:disabled),select:not(:disabled),textarea:not(:disabled),button:not(:disabled),*[tabindex]';
function findFirstFocusableNode(element, onlyDescendants) {
    if (onlyDescendants === void 0) { onlyDescendants = true; }
    if (!onlyDescendants && dom_1.matches(element, exports.FOCUSABLE_SELECTOR)) {
        return element;
    }
    return element.querySelector(exports.FOCUSABLE_SELECTOR);
}
exports.findFirstFocusableNode = findFirstFocusableNode;
function focusFirstFocusableNode(element, onlyDescendants) {
    if (onlyDescendants === void 0) { onlyDescendants = true; }
    var firstFocusable = findFirstFocusableNode(element, onlyDescendants);
    if (firstFocusable) {
        firstFocusable.focus();
    }
}
exports.focusFirstFocusableNode = focusFirstFocusableNode;
function findLastFocusableNode(element, onlyDescendants) {
    if (onlyDescendants === void 0) { onlyDescendants = true; }
    if (!onlyDescendants && dom_1.matches(element, exports.FOCUSABLE_SELECTOR)) {
        return element;
    }
    var allFocusable = element.querySelectorAll(exports.FOCUSABLE_SELECTOR);
    return allFocusable[allFocusable.length - 1];
}
exports.findLastFocusableNode = findLastFocusableNode;
function focusLastFocusableNode(element, onlyDescendants) {
    if (onlyDescendants === void 0) { onlyDescendants = true; }
    var lastFocusable = findLastFocusableNode(element, onlyDescendants);
    if (lastFocusable) {
        lastFocusable.focus();
    }
}
exports.focusLastFocusableNode = focusLastFocusableNode;
