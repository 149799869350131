"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// for now we just use rAF but we should use some kind of fastDOM implementation
function read(callback) {
    return requestAnimationFrame(callback);
}
exports.read = read;
function write(callback) {
    return requestAnimationFrame(callback);
}
exports.write = write;
function clear(taskID) {
    return cancelAnimationFrame(taskID);
}
exports.clear = clear;
