"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Weekdays;
(function (Weekdays) {
    Weekdays[Weekdays["Sunday"] = 0] = "Sunday";
    Weekdays[Weekdays["Monday"] = 1] = "Monday";
    Weekdays[Weekdays["Tuesday"] = 2] = "Tuesday";
    Weekdays[Weekdays["Wednesday"] = 3] = "Wednesday";
    Weekdays[Weekdays["Thursday"] = 4] = "Thursday";
    Weekdays[Weekdays["Friday"] = 5] = "Friday";
    Weekdays[Weekdays["Saturday"] = 6] = "Saturday";
})(Weekdays = exports.Weekdays || (exports.Weekdays = {}));
var Months;
(function (Months) {
    Months[Months["January"] = 0] = "January";
    Months[Months["February"] = 1] = "February";
    Months[Months["March"] = 2] = "March";
    Months[Months["April"] = 3] = "April";
    Months[Months["May"] = 4] = "May";
    Months[Months["June"] = 5] = "June";
    Months[Months["July"] = 6] = "July";
    Months[Months["August"] = 7] = "August";
    Months[Months["September"] = 8] = "September";
    Months[Months["October"] = 9] = "October";
    Months[Months["November"] = 10] = "November";
    Months[Months["December"] = 11] = "December";
})(Months = exports.Months || (exports.Months = {}));
var TimeUnit;
(function (TimeUnit) {
    TimeUnit[TimeUnit["Second"] = 1000] = "Second";
    TimeUnit[TimeUnit["Minute"] = 60000] = "Minute";
    TimeUnit[TimeUnit["Hour"] = 3600000] = "Hour";
    TimeUnit[TimeUnit["Day"] = 86400000] = "Day";
    TimeUnit[TimeUnit["Week"] = 604800000] = "Week";
    TimeUnit[TimeUnit["Year"] = 31536000000] = "Year";
})(TimeUnit = exports.TimeUnit || (exports.TimeUnit = {}));
function getYearForRange(_a) {
    var start = _a.start, end = _a.end;
    if (start) {
        return start.getFullYear();
    }
    if (end) {
        return end.getFullYear();
    }
    return (new Date()).getFullYear();
}
exports.getYearForRange = getYearForRange;
function getMonthForRange(_a) {
    var start = _a.start, end = _a.end;
    if (start) {
        return start.getMonth();
    }
    if (end) {
        return end.getMonth();
    }
    return (new Date()).getMonth();
}
exports.getMonthForRange = getMonthForRange;
function abbreviationForWeekday(weekday) {
    return Weekdays[weekday].substring(0, 2);
}
exports.abbreviationForWeekday = abbreviationForWeekday;
var WEEK_LENGTH = 7;
function getWeeksForMonth(month, year, weekStartsOn) {
    if (weekStartsOn === void 0) { weekStartsOn = Weekdays.Sunday; }
    var firstOfMonth = new Date(year, month, 1);
    var firstDayOfWeek = firstOfMonth.getDay();
    var weeks = [[]];
    var currentWeek = weeks[0];
    var currentDate = firstOfMonth;
    var orderedWeekday = getOrderedWeekdays(weekStartsOn);
    for (var i = 0; i < orderedWeekday.indexOf(firstDayOfWeek); i++) {
        currentWeek.push(null);
    }
    while (currentDate.getMonth() === month) {
        if (currentWeek.length === WEEK_LENGTH) {
            currentWeek = [];
            weeks.push(currentWeek);
        }
        currentWeek.push(currentDate);
        currentDate = new Date(year, month, currentDate.getDate() + 1);
    }
    while (currentWeek.length < 7) {
        currentWeek.push(null);
    }
    return weeks;
}
exports.getWeeksForMonth = getWeeksForMonth;
function dateIsInRange(day, range) {
    if (day == null) {
        return false;
    }
    var start = range.start, end = range.end;
    return Boolean((start && day > start) && (end && day < end));
}
exports.dateIsInRange = dateIsInRange;
function dateIsSelected(day, range) {
    if (day == null) {
        return false;
    }
    var start = range.start, end = range.end;
    return Boolean((start && isSameDay(start, day)) || (end && isSameDay(end, day)));
}
exports.dateIsSelected = dateIsSelected;
function isSameDay(day1, day2) {
    return ((day1.getDate() === day2.getDate()) &&
        (day1.getMonth() === day2.getMonth()) &&
        (day1.getFullYear() === day2.getFullYear()));
}
exports.isSameDay = isSameDay;
function getDateDiff(resolution, date, today) {
    if (today === void 0) { today = new Date(); }
    return Math.floor((today.getTime() - date.getTime()) / resolution);
}
exports.getDateDiff = getDateDiff;
function getNewRange(range, selected) {
    if (range == null) {
        return { start: selected, end: selected };
    }
    var start = range.start, end = range.end;
    if (end && (isDateAfter(start, end) || isDateBefore(start, end))) {
        return { start: selected, end: selected };
    }
    if (start) {
        if (isDateBefore(selected, start)) {
            return { start: selected, end: selected };
        }
        return { start: start, end: selected };
    }
    if (end) {
        if (isDateBefore(selected, end)) {
            return { start: selected, end: end };
        }
        return { start: start || end, end: selected };
    }
    return { start: selected, end: selected };
}
exports.getNewRange = getNewRange;
function getNextDisplayMonth(month) {
    if (Months[month] === Months[11]) {
        return 0;
    }
    return month + 1;
}
exports.getNextDisplayMonth = getNextDisplayMonth;
function getNextDisplayYear(month, year) {
    if (Months[month] === Months[11]) {
        return year + 1;
    }
    return year;
}
exports.getNextDisplayYear = getNextDisplayYear;
function getPreviousDisplayMonth(month) {
    if (Months[month] === Months[0]) {
        return 11;
    }
    return month - 1;
}
exports.getPreviousDisplayMonth = getPreviousDisplayMonth;
function getPreviousDisplayYear(month, year) {
    if (Months[month] === Months[0]) {
        return year - 1;
    }
    return year;
}
exports.getPreviousDisplayYear = getPreviousDisplayYear;
function isDateAfter(date, dateToCompare) {
    return date.getTime() > dateToCompare.getTime();
}
exports.isDateAfter = isDateAfter;
function isDateBefore(date, dateToCompare) {
    return date.getTime() < dateToCompare.getTime();
}
exports.isDateBefore = isDateBefore;
function isLessThanOneMinuteAgo(date, today) {
    if (today === void 0) { today = new Date(); }
    return (isDateBefore(date, today) && today.getTime() - date.getTime() < TimeUnit.Minute);
}
exports.isLessThanOneMinuteAgo = isLessThanOneMinuteAgo;
function isLessThanOneHourAgo(date, today) {
    if (today === void 0) { today = new Date(); }
    return (isDateBefore(date, today) && today.getTime() - date.getTime() < TimeUnit.Hour);
}
exports.isLessThanOneHourAgo = isLessThanOneHourAgo;
function isLessThanOneDayAgo(date, today) {
    if (today === void 0) { today = new Date(); }
    return (isDateBefore(date, today) && today.getTime() - date.getTime() < TimeUnit.Day);
}
exports.isLessThanOneDayAgo = isLessThanOneDayAgo;
function isLessThanOneWeekAgo(date, today) {
    if (today === void 0) { today = new Date(); }
    return (isDateBefore(date, today) && today.getTime() - date.getTime() < TimeUnit.Week);
}
exports.isLessThanOneWeekAgo = isLessThanOneWeekAgo;
function isLessThanOneYearAgo(date, today) {
    if (today === void 0) { today = new Date(); }
    return (isDateBefore(date, today) && today.getTime() - date.getTime() < TimeUnit.Year);
}
exports.isLessThanOneYearAgo = isLessThanOneYearAgo;
function isSameMonthAndYear(source, target) {
    return (source.getFullYear() === target.getFullYear() &&
        source.getMonth() === target.getMonth());
}
exports.isSameMonthAndYear = isSameMonthAndYear;
function isSameDate(source, target) {
    return (isSameMonthAndYear(source, target) && source.getDate() === target.getDate());
}
exports.isSameDate = isSameDate;
function isToday(date) {
    var today = new Date();
    return isSameDate(today, date);
}
exports.isToday = isToday;
function isYesterday(date) {
    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return isSameDate(yesterday, date);
}
exports.isYesterday = isYesterday;
var WEEKDAYS = [
    Weekdays.Sunday,
    Weekdays.Monday,
    Weekdays.Tuesday,
    Weekdays.Wednesday,
    Weekdays.Thursday,
    Weekdays.Friday,
    Weekdays.Saturday,
];
function getOrderedWeekdays(weekStartsOn) {
    var weekDays = WEEKDAYS.slice();
    var restOfDays = weekDays.splice(weekStartsOn);
    return restOfDays.concat(weekDays);
}
