import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import { useGridRegisterPipeProcessor } from '@mui/x-data-grid/internals';
export function addPinnedRow({
  groupingParams,
  rowModel,
  rowId,
  position,
  apiRef,
  isAutoGenerated
}) {
  var _groupingParams$addit;

  const idRowsLookup = _extends({}, groupingParams.idRowsLookup);

  const tree = _extends({}, groupingParams.tree); // TODO: warn if id is already present in `props.rows`


  idRowsLookup[rowId] = rowModel; // Do not push it to ids list so that pagination is not affected by pinned rows
  // ids.push(rowId);

  tree[rowId] = {
    id: rowId,
    isAutoGenerated,
    parent: null,
    depth: 0,
    groupingKey: null,
    groupingField: null,
    isPinned: true
  };
  apiRef.current.unstable_caches.rows.idRowsLookup[rowId] = _extends({}, rowModel);
  apiRef.current.unstable_caches.rows.idToIdLookup[rowId] = rowId;
  const previousPinnedRows = ((_groupingParams$addit = groupingParams.additionalRowGroups) == null ? void 0 : _groupingParams$addit.pinnedRows) || {};
  const newPinnedRow = {
    id: rowId,
    model: rowModel
  };
  return _extends({}, groupingParams, {
    idRowsLookup,
    tree,
    additionalRowGroups: _extends({}, groupingParams.additionalRowGroups, {
      pinnedRows: _extends({}, previousPinnedRows, {
        [position]: [...(previousPinnedRows[position] || []), newPinnedRow]
      })
    })
  });
}
export function removePinnedRow({
  groupingParams,
  rowId,
  apiRef
}) {
  const idRowsLookup = _extends({}, groupingParams.idRowsLookup);

  const tree = _extends({}, groupingParams.tree);

  delete idRowsLookup[rowId];
  delete tree[rowId];
  delete apiRef.current.unstable_caches.rows.idRowsLookup[rowId];
  delete apiRef.current.unstable_caches.rows.idToIdLookup[rowId];
}
export const useGridRowPinningPreProcessors = apiRef => {
  const previousPinnedRowsCacheRef = React.useRef(null);
  const addPinnedRows = React.useCallback(groupingParams => {
    var _pinnedRowsCache$topI, _pinnedRowsCache$bott;

    const pinnedRowsCache = apiRef.current.unstable_caches.pinnedRows || {};
    const previousPinnedRowsCache = previousPinnedRowsCacheRef.current;
    previousPinnedRowsCacheRef.current = pinnedRowsCache;

    let newGroupingParams = _extends({}, groupingParams, {
      additionalRowGroups: _extends({}, groupingParams.additionalRowGroups, {
        // reset pinned rows state
        pinnedRows: {}
      })
    });

    if (previousPinnedRowsCache) {
      var _previousPinnedRowsCa, _previousPinnedRowsCa2;

      (_previousPinnedRowsCa = previousPinnedRowsCache.topIds) == null ? void 0 : _previousPinnedRowsCa.forEach(rowId => {
        removePinnedRow({
          groupingParams: newGroupingParams,
          rowId,
          apiRef
        });
      });
      (_previousPinnedRowsCa2 = previousPinnedRowsCache.bottomIds) == null ? void 0 : _previousPinnedRowsCa2.forEach(rowId => {
        removePinnedRow({
          groupingParams: newGroupingParams,
          rowId,
          apiRef
        });
      });
    }

    (_pinnedRowsCache$topI = pinnedRowsCache.topIds) == null ? void 0 : _pinnedRowsCache$topI.forEach(rowId => {
      newGroupingParams = addPinnedRow({
        groupingParams: newGroupingParams,
        rowModel: pinnedRowsCache.idLookup[rowId],
        rowId,
        position: 'top',
        apiRef,
        isAutoGenerated: false
      });
    });
    (_pinnedRowsCache$bott = pinnedRowsCache.bottomIds) == null ? void 0 : _pinnedRowsCache$bott.forEach(rowId => {
      newGroupingParams = addPinnedRow({
        groupingParams: newGroupingParams,
        rowModel: pinnedRowsCache.idLookup[rowId],
        rowId,
        position: 'bottom',
        apiRef,
        isAutoGenerated: false
      });
    }); // If row with the same `id` is present both in `rows` and `pinnedRows` - remove it from `ids`

    newGroupingParams.ids = newGroupingParams.ids.filter(rowId => {
      if (newGroupingParams.tree[rowId] && newGroupingParams.tree[rowId].isPinned) {
        return false;
      }

      return true;
    });
    return newGroupingParams;
  }, [apiRef]);
  useGridRegisterPipeProcessor(apiRef, 'hydrateRows', addPinnedRows);
};