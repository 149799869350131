"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Loading_1 = require("../../actions/Loading");
exports.ActionType = Loading_1.ActionType;
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
function validateAction(action) {
    var validator = utils_1.createActionValidator(Loading_1.ActionType);
    return type_validate_1.validate(action, validator);
}
exports.validateAction = validateAction;
