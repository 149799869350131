"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var collection_1 = require("../util/collection");
/**
 * Shopify App Bridge can be extended with hooks, which run when actions are dispatched and updated.
 * Hooks are middleware functions that can modify or cancel actions.
 *
 * The [validation middleware](../validate/README.md) is implemented using hooks.
 *
 * @remarks
 * Here’s an example hook that modifies all Toast show actions to have a duration of five seconds.
 *
 * ```ts
 * import createApp, {LifecycleHook, DispatchActionHook} from '@shopify/app-bridge';
 * import {Toast} from '@shopify/app-bridge/actions'
 *
 * const app = createApp({apiKey: API_KEY, shopOrigin: SHOP_ORIGIN});
 *
 * function makeToastsFiveSeconds: DispatchActionHook(next) {
 *   return function(action){
 *       if(action.type === Toast.ActionType.SHOW) {
 *         const modifiedAction = {
 *           ...action,
 *           payload: {
 *             ...action.payload,
 *             duration: 5000,
 *           },
 *         };
 *         return next(modifiedAction);
 *       } else {
 *         // don’t modify non-Toast actions
 *         return next(action);
 *       }
 *   }
 * }
 *
 * app.hooks.set(LifecycleHook.DispatchAction, makeToastsFiveSeconds);
 * ```
 *
 * The hook function `makeToastsFiveSeconds()` takes a `next()` function as its argument.
 * The hook function returns an anonymous function, which takes the action being dispatched as its argument.
 *
 * To modify an action, call `next()` with the modified action, as in the example.
 * To cancel an action, don’t call `next()`, and the action will not be dispatched.
 */
var Hooks = /** @class */ (function () {
    function Hooks() {
        this.map = {};
    }
    Hooks.prototype.set = function (hook, handler) {
        if (!this.map.hasOwnProperty(hook)) {
            this.map[hook] = [];
        }
        var value = { handler: handler, remove: function () { } };
        var remove = collection_1.addAndRemoveFromCollection(this.map[hook], value);
        value = { handler: handler, remove: remove };
        return remove;
    };
    Hooks.prototype.get = function (hook) {
        var value = this.map[hook];
        return value ? value.map(function (val) { return val.handler; }) : undefined;
    };
    Hooks.prototype.run = function (hook, final, context) {
        var initialArgs = [];
        for (var _i = 3; _i < arguments.length; _i++) {
            initialArgs[_i - 3] = arguments[_i];
        }
        var index = 0;
        var handlers = this.get(hook) || [];
        function handler() {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var childHandler = handlers[index++];
            if (childHandler) {
                return childHandler(handler).apply(context, args);
            }
            return final.apply(context, args);
        }
        return handler.apply(context, initialArgs);
    };
    return Hooks;
}());
exports.default = Hooks;
