"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Action Types for the Features group
 * @public
 */
var ActionType;
(function (ActionType) {
    ActionType["UPDATE"] = "APP::FEATURES::UPDATE";
    ActionType["REQUEST"] = "APP::FEATURES::REQUEST";
    ActionType["REQUEST_UPDATE"] = "APP::FEATURES::REQUEST::UPDATE";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
/**
 * Actions available to the Features group
 * @public
 */
var Action;
(function (Action) {
    Action["UPDATE"] = "UPDATE";
    Action["REQUEST"] = "REQUEST";
    Action["REQUEST_UPDATE"] = "REQUEST::UPDATE";
})(Action = exports.Action || (exports.Action = {}));
