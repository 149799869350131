"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var client_1 = require("../client");
var actions_1 = require("../actions");
var Error_1 = require("../actions/Error");
var actions_2 = require("./actions");
var type_validate_1 = require("./type-validate");
var utils_1 = require("./utils");
var helper_1 = require("../actions/helper");
function updateValidator(localOrigin, group, options) {
    switch (group) {
        case actions_1.Group.Button:
            return actions_2.Button.validateProps(options);
        case actions_1.Group.ButtonGroup:
            return actions_2.ButtonGroup.validateProps(options);
        case actions_1.Group.Modal:
            return actions_2.Modal.validateProps(options, localOrigin);
        case actions_1.Group.Menu:
            return actions_2.Menu.validateProps(options);
        case actions_1.Group.Link:
            return actions_2.Link.validateProps(options);
        case actions_1.Group.TitleBar:
            return actions_2.TitleBar.validateProps(options);
        case actions_1.Group.ResourcePicker:
            return actions_2.ResourcePicker.validateProps(options);
        case actions_1.Group.Toast:
            return actions_2.Toast.validateProps(options);
        case actions_1.Group.ContextualSaveBar:
            return actions_2.ContextualSaveBar.validateProps(options);
    }
}
function dispatchPayloadValidator(action, localOrigin) {
    switch (action.group) {
        case actions_1.Group.Button:
            return actions_2.Button.validateAction(action);
        case actions_1.Group.ButtonGroup:
            return actions_2.ButtonGroup.validateAction(action);
        case actions_1.Group.Modal:
            return actions_2.Modal.validateAction(action, localOrigin);
        case actions_1.Group.Menu:
            return actions_2.Menu.validateAction(action);
        case actions_1.Group.TitleBar:
            return actions_2.TitleBar.validateAction(action);
        case actions_1.Group.ResourcePicker:
            return actions_2.ResourcePicker.validateAction(action);
        case actions_1.Group.Loading:
            return actions_2.Loading.validateAction(action);
        case actions_1.Group.Toast:
            return actions_2.Toast.validateAction(action);
        case actions_1.Group.Cart:
            return actions_2.Cart.validateAction(action);
        case actions_1.Group.Navigation:
            return actions_2.Navigation.validateAction(action);
        case actions_1.Group.Print:
            return actions_2.Print.validateAction(action);
        case actions_1.Group.Scanner:
            return actions_2.Scanner.validateAction(action);
        case actions_1.Group.Fullscreen:
            return actions_2.Fullscreen.validateAction(action);
        case actions_1.Group.ContextualSaveBar:
            return actions_2.ContextualSaveBar.validateAction(action);
        case actions_1.Group.Link:
            return actions_2.Link.validateAction(action);
    }
}
function dispatchValidator(action, origin) {
    var errors = type_validate_1.validate(action, type_validate_1.matchesObject({
        group: type_validate_1.matchesEnum(actions_1.Group, {
            message: function (_, value) { return "Unknown or unsupported action group `" + value + "`"; },
        }),
        version: type_validate_1.matchesString(),
    }));
    if (errors) {
        return Error_1.invalidAction(action, utils_1.actionMessage(errors));
    }
    var payloadErrors = dispatchPayloadValidator(action, origin);
    return payloadErrors ? Error_1.invalidPayload(action, utils_1.actionMessage(payloadErrors)) : action;
}
exports.connectValidatorToUpdateHook = function (next) {
    return function (options) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        // The group should be staticly set to the default initial group as certain components
        // (ex. Button and ButtonGroup) can be dynamically grouped under a new group (ex. TitleBar)
        // We accept partial options so the validator should be called on merged new and old options
        var mergedOptions = helper_1.getMergedProps(this.options, options);
        var errors = updateValidator(this.app.localOrigin, this.defaultGroup, mergedOptions);
        if (errors) {
            throw Error_1.fromAction(utils_1.actionMessage(errors), Error_1.ActionType.INVALID_OPTIONS);
        }
        return next.apply(void 0, [options].concat(args));
    };
};
exports.connectValidatorToDispatchHook = function (next) {
    return function (action) {
        var finalAction = dispatchValidator(action, this.localOrigin);
        return next(finalAction);
    };
};
exports.validatorMiddleware = function (hooks) {
    hooks.set(client_1.LifecycleHook.UpdateAction, exports.connectValidatorToUpdateHook);
    hooks.set(client_1.LifecycleHook.DispatchAction, exports.connectValidatorToDispatchHook);
};
