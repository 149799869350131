"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Error_1 = require("./actions/Error");
var validator_1 = require("./actions/validator");
var collection_1 = require("./util/collection");
var env_1 = require("./util/env");
var Context;
(function (Context) {
    Context["Modal"] = "Modal";
    Context["Main"] = "Main";
})(Context = exports.Context || (exports.Context = {}));
/**
 * Create a MessageTransport from an IFrame.
 * @remarks
 * Used on the host-side to create a postMessage MessageTransport.
 * @beta
 */
function fromFrame(frame, localOrigin, context) {
    var handlers = [];
    if (typeof frame === 'undefined' || !frame.ownerDocument || !frame.ownerDocument.defaultView) {
        throw Error_1.fromAction('App frame is undefined', Error_1.AppActionType.WINDOW_UNDEFINED);
    }
    var parent = frame.ownerDocument.defaultView;
    parent.addEventListener('message', function (event) {
        if (event.origin !== localOrigin || !validator_1.isAppMessage(event)) {
            return;
        }
        for (var _i = 0, handlers_1 = handlers; _i < handlers_1.length; _i++) {
            var handler = handlers_1[_i];
            handler(event);
        }
    });
    return {
        context: context,
        localOrigin: localOrigin,
        frameWindow: frame.contentWindow,
        hostFrame: parent,
        dispatch: function (message) {
            var contentWindow = frame.contentWindow;
            if (contentWindow) {
                contentWindow.postMessage(message, '*');
            }
        },
        subscribe: function (handler) {
            return collection_1.addAndRemoveFromCollection(handlers, handler);
        },
    };
}
exports.fromFrame = fromFrame;
/**
 * Create a MessageTransport from a parent window.
 * @remarks
 * Used on the client-side to create a postMessage MessageTransport.
 * @internalremarks
 * In frameless mode, message should be dispatched via SmartWebView.handleMessage instead of postMessage.
 * @beta
 */
function fromWindow(contentWindow, localOrigin) {
    var handlers = [];
    if (typeof window !== undefined) {
        window.addEventListener('message', function (event) {
            if ((window === contentWindow && !env_1.isFrameless) ||
                event.source !== contentWindow ||
                !(validator_1.isAppBridgeAction(event.data.payload) || validator_1.isAppMessage(event))) {
                return;
            }
            for (var _i = 0, handlers_2 = handlers; _i < handlers_2.length; _i++) {
                var handler = handlers_2[_i];
                handler(event);
            }
        });
    }
    return {
        localOrigin: localOrigin,
        hostFrame: contentWindow,
        dispatch: function (message) {
            if (!message.source || !message.source.shopOrigin) {
                return;
            }
            if (env_1.isFrameless && window && window.SmartWebView) {
                window.SmartWebView.handleMessage('frameless://fromClient', JSON.stringify(message));
                return;
            }
            var messageOrigin = "https://" + message.source.shopOrigin;
            contentWindow.postMessage(message, messageOrigin);
        },
        subscribe: function (handler) {
            return collection_1.addAndRemoveFromCollection(handlers, handler);
        },
    };
}
exports.fromWindow = fromWindow;
