"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Action;
(function (Action) {
    Action["UPDATE"] = "UPDATE";
})(Action = exports.Action || (exports.Action = {}));
var ActionType;
(function (ActionType) {
    ActionType["UPDATE"] = "APP::TITLEBAR::UPDATE";
    ActionType["BUTTON_CLICK"] = "APP::TITLEBAR::BUTTONS::BUTTON::CLICK";
    ActionType["BUTTON_UPDATE"] = "APP::TITLEBAR::BUTTONS::BUTTON::UPDATE";
    ActionType["BUTTON_GROUP_UPDATE"] = "APP::TITLEBAR::BUTTONS::BUTTONGROUP::UPDATE";
    ActionType["BREADCRUMBS_CLICK"] = "APP::TITLEBAR::BREADCRUMBS::BUTTON::CLICK";
    ActionType["BREADCRUMBS_UPDATE"] = "APP::TITLEBAR::BREADCRUMBS::BUTTON::UPDATE";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
