"use strict";
/**
 * Action Types for the Features group
 * @public
 */
Object.defineProperty(exports, "__esModule", { value: true });
var ActionType;
(function (ActionType) {
    ActionType["OPEN_CAMERA"] = "APP::SCANNER::OPEN::CAMERA";
    ActionType["OPEN_NFC"] = "APP::SCANNER::OPEN::NFC";
    ActionType["CAPTURE"] = "APP::SCANNER::CAPTURE";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
/**
 * Actions available to the Features group
 * @public
 */
var Action;
(function (Action) {
    Action["OPEN_CAMERA"] = "OPEN::CAMERA";
    Action["OPEN_NFC"] = "OPEN::NFC";
    Action["CAPTURE"] = "CAPTURE";
})(Action = exports.Action || (exports.Action = {}));
