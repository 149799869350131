"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function mergeProps(obj, newObj) {
    if (newObj === undefined) {
        return undefined;
    }
    // If setting to a different prototype or a non-object or non-array, don't merge any props
    if (typeof obj === 'undefined' ||
        !Object.getPrototypeOf(obj).isPrototypeOf(newObj) ||
        (newObj.constructor.name !== 'Object' && newObj.constructor.name !== 'Array')) {
        return newObj;
    }
    var clone = {};
    Object.keys(newObj).forEach(function (key) {
        var exists = obj.hasOwnProperty(key);
        if (!exists) {
            clone[key] = newObj[key];
        }
        else {
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                clone[key] = mergeProps(obj[key], newObj[key]);
            }
            else {
                clone[key] = newObj[key];
            }
        }
    });
    // Copy old props that are not present in new object only if this is a simple object
    Object.keys(obj).forEach(function (key) {
        var exists = newObj.hasOwnProperty(key);
        if (!exists) {
            clone[key] = obj[key];
        }
    });
    // Set prototype of cloned object to match original
    Object.setPrototypeOf(clone, Object.getPrototypeOf(obj));
    return clone;
}
exports.default = mergeProps;
