"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var Toast_1 = require("../Toast");
var Toast_2 = require("../Toast");
exports.clear = Toast_2.clear;
exports.show = Toast_2.show;
var Flash = /** @class */ (function (_super) {
    __extends(Flash, _super);
    function Flash() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Flash;
}(Toast_1.Toast));
exports.Flash = Flash;
function create(app, options) {
    return new Flash(app, options);
}
exports.create = create;
