"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function nodeContainsDescendant(rootNode, descendant) {
    if (rootNode === descendant) {
        return true;
    }
    var parent = descendant.parentNode;
    while (parent != null) {
        if (parent === rootNode) {
            return true;
        }
        parent = parent.parentNode;
    }
    return false;
}
exports.nodeContainsDescendant = nodeContainsDescendant;
/* tslint:disable */
// Polyfill for .matches()
// https://developer.mozilla.org/en/docs/Web/API/Element/matches
function matches(node, selector) {
    if (node.matches) {
        return node.matches(selector);
    }
    var matches = (node.ownerDocument || document).querySelectorAll(selector);
    var i = matches.length;
    while (--i >= 0 && matches.item(i) !== node) { }
    return i > -1;
}
exports.matches = matches;
;
// Polyfill for .closest()
// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
function closest(node, selector) {
    if (node.closest) {
        return node.closest(selector);
    }
    var matches = document.querySelectorAll(selector);
    var i;
    var el = node;
    do {
        el = el.parentElement;
        i = matches.length;
        while (--i >= 0 && matches.item(i) !== el) {
            continue;
        }
        ;
    } while ((i < 0) && (el));
    return el;
}
exports.closest = closest;
/* tslint:enable */
