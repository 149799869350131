"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var feature_detect_1 = require("./feature-detect");
function addEventListener(target, eventName, handler, options) {
    if (options === void 0) { options = {}; }
    var wrappedHandler = !feature_detect_1.supportsEventListenerOnceOption() && options.once
        ? once(target, eventName, handler)
        : handler;
    if (feature_detect_1.supportsEventListenerPassiveOption() || feature_detect_1.supportsEventListenerOnceOption()) {
        var addListener = target.addEventListener;
        return addListener.call(target, eventName, handler, options);
    }
    return target.addEventListener(eventName, wrappedHandler, options.capture);
}
exports.addEventListener = addEventListener;
function removeEventListener(target, eventName, handler, capture) {
    return target.removeEventListener(eventName, handler, capture);
}
exports.removeEventListener = removeEventListener;
function once(target, eventName, handler) {
    return function selfRemovingHandler(event) {
        handler.call(event.currentTarget, event);
        target.removeEventListener(eventName, handler);
    };
}
