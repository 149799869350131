"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Print_1 = require("../../actions/Print");
exports.ActionType = Print_1.ActionType;
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
function validateAction(action) {
    return type_validate_1.validate(action, utils_1.createActionValidator(Print_1.ActionType));
}
exports.validateAction = validateAction;
