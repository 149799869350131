"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var actions_1 = require("../../actions");
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
function matchesAbsolutePath(value) {
    return value.match('^https?://')
        ? undefined
        : [
            {
                value: value,
                error: 'invalid_absolute_url',
                message: 'expected string to start with `https://` or `http://`',
            },
        ];
}
exports.matchesAbsolutePath = matchesAbsolutePath;
function getSectionSchema(payload) {
    var isProductVariant = payload &&
        payload.section &&
        payload.section.resource &&
        payload.section.name === actions_1.Redirect.ResourceType.Product;
    var resourceSchema = {
        create: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
        id: type_validate_1.makeOptional(type_validate_1.matchesString()),
    };
    var productVariantSchema = __assign({}, resourceSchema, { variant: type_validate_1.makeOptional(type_validate_1.matchesObject(resourceSchema)) });
    return type_validate_1.matchesObject({
        section: type_validate_1.matchesObject({
            name: type_validate_1.matchesEnum(actions_1.Redirect.ResourceType),
            resource: type_validate_1.makeOptional(type_validate_1.matchesObject(isProductVariant ? productVariantSchema : resourceSchema)),
        }),
    });
}
exports.getSectionSchema = getSectionSchema;
function validateAction(action) {
    var newContextSchema = type_validate_1.matchesObject({ newContext: type_validate_1.makeOptional(type_validate_1.matchesBoolean()) });
    var actionType = actions_1.Redirect.ActionType;
    var schema;
    switch (action.type) {
        case actions_1.History.ActionType.PUSH:
        case actions_1.History.ActionType.REPLACE:
            actionType = actions_1.History.ActionType;
            schema = utils_1.relativePathSchema;
            break;
        case actions_1.Redirect.ActionType.APP:
            schema = utils_1.relativePathSchema;
            break;
        case actions_1.Redirect.ActionType.REMOTE:
            schema = type_validate_1.composeSchemas(type_validate_1.matchesObject({
                url: type_validate_1.composeSchemas(type_validate_1.matchesString(), function (value) { return matchesAbsolutePath(value); }),
            }), newContextSchema);
            break;
        case actions_1.Redirect.ActionType.ADMIN_PATH:
            schema = type_validate_1.composeSchemas(utils_1.relativePathSchema, newContextSchema);
            break;
        case actions_1.Redirect.ActionType.ADMIN_SECTION:
            schema = type_validate_1.composeSchemas(getSectionSchema(action.payload), newContextSchema);
            break;
    }
    return type_validate_1.validate(action, utils_1.createActionValidator(actionType, schema));
}
exports.validateAction = validateAction;
