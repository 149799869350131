"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var helper_1 = require("../helper");
var types_1 = require("../types");
var types_2 = require("./types");
/**
 * A set of Actions for displaying a Camera or NFC Scanner component
 * @public
 */
var Scanner = /** @class */ (function (_super) {
    __extends(Scanner, _super);
    function Scanner(app, options) {
        return _super.call(this, app, types_1.Group.Scanner, types_1.Group.Scanner, options ? options.id : undefined) || this;
    }
    /**
     * @public
     */
    Scanner.prototype.dispatch = function (action) {
        switch (action) {
            case types_2.Action.OPEN_CAMERA:
                this.dispatchScannerAction(types_2.ActionType.OPEN_CAMERA);
                break;
            case types_2.Action.OPEN_NFC:
                this.dispatchScannerAction(types_2.ActionType.OPEN_NFC);
                break;
        }
        return this;
    };
    /**
     * @internal
     */
    Scanner.prototype.dispatchScannerAction = function (type) {
        this.app.dispatch(helper_1.actionWrapper({
            type: type,
            group: types_1.Group.Scanner,
            payload: {
                id: this.id,
            },
        }));
    };
    return Scanner;
}(helper_1.ActionSet));
exports.Scanner = Scanner;
/**
 * @public
 */
function create(app, options) {
    return new Scanner(app, options);
}
exports.create = create;
