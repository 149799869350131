"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ActionType;
(function (ActionType) {
    ActionType["SHOW"] = "APP::TOAST::SHOW";
    ActionType["CLEAR"] = "APP::TOAST::CLEAR";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
var Action;
(function (Action) {
    Action["SHOW"] = "SHOW";
    Action["CLEAR"] = "CLEAR";
})(Action = exports.Action || (exports.Action = {}));
