"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ResourcePicker_1 = require("../../actions/ResourcePicker");
exports.ActionType = ResourcePicker_1.ActionType;
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
var resourcePickerOptions = type_validate_1.matchesObject({
    initialQuery: type_validate_1.makeOptional(type_validate_1.matchesString()),
    selectMultiple: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
    showHidden: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
    showVariants: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
    actionVerb: type_validate_1.makeOptional(type_validate_1.matchesEnum(ResourcePicker_1.ActionVerb)),
});
var resourcePickerActionSchema = type_validate_1.matchesObject({
    resourceType: type_validate_1.matchesEnum(ResourcePicker_1.ResourceType),
    options: type_validate_1.makeOptional(resourcePickerOptions),
});
var selectionSchema = type_validate_1.matchesObject({
    selection: type_validate_1.matchesArray(type_validate_1.matchesObject({
        id: type_validate_1.matchesString(),
    })),
});
function validateProps(props) {
    return type_validate_1.validate(props, resourcePickerOptions);
}
exports.validateProps = validateProps;
function validateAction(action) {
    switch (action.type) {
        case ResourcePicker_1.ActionType.UPDATE:
        case ResourcePicker_1.ActionType.OPEN:
            return type_validate_1.validate(action, utils_1.createActionValidator(ResourcePicker_1.ActionType, resourcePickerActionSchema, false, true));
        case ResourcePicker_1.ActionType.SELECT:
            return type_validate_1.validate(action, utils_1.createActionValidator(ResourcePicker_1.ActionType, selectionSchema, true, true));
        case ResourcePicker_1.ActionType.CANCEL:
        case ResourcePicker_1.ActionType.CLOSE:
        default:
            return type_validate_1.validate(action, utils_1.createActionValidator(ResourcePicker_1.ActionType));
    }
}
exports.validateAction = validateAction;
