"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var helper_1 = require("../helper");
var types_1 = require("../types");
var types_2 = require("./types");
function errorActionWrapperWithId(type, action, message) {
    var castPayload = action.payload;
    return helper_1.actionWrapper({
        type: type,
        group: types_1.Group.Error,
        payload: {
            action: action,
            message: message,
            type: type,
            id: castPayload && castPayload.id ? castPayload.id : undefined,
        },
    });
}
var Message;
(function (Message) {
    Message["MISSING_PAYLOAD"] = "Missing payload";
    Message["INVALID_PAYLOAD_ID"] = "Id in payload is missing or invalid";
})(Message = exports.Message || (exports.Message = {}));
function invalidPayload(action, message) {
    return errorActionWrapperWithId(types_2.ActionType.INVALID_PAYLOAD, action, message || "The action's payload is missing required properties or has invalid properties");
}
exports.invalidPayload = invalidPayload;
function invalidActionType(action, message) {
    return helper_1.actionWrapper({
        group: types_1.Group.Error,
        payload: {
            action: action,
            message: message || 'The action type is invalid or unsupported',
            type: types_2.ActionType.INVALID_ACTION_TYPE,
        },
        type: types_2.ActionType.INVALID_ACTION_TYPE,
    });
}
exports.invalidActionType = invalidActionType;
function invalidAction(action, message) {
    return helper_1.actionWrapper({
        group: types_1.Group.Error,
        payload: {
            action: action,
            message: message || "The action's has missing/invalid values for `group`, `type` or `version`",
            type: types_2.ActionType.INVALID_ACTION,
        },
        type: types_2.ActionType.INVALID_ACTION,
    });
}
exports.invalidAction = invalidAction;
function unexpectedAction(action, message) {
    return helper_1.actionWrapper({
        group: types_1.Group.Error,
        payload: {
            action: action,
            message: message || 'Action cannot be called at this time',
            type: types_2.ActionType.UNEXPECTED_ACTION,
        },
        type: types_2.ActionType.UNEXPECTED_ACTION,
    });
}
exports.unexpectedAction = unexpectedAction;
function unsupportedOperationAction(action, message) {
    return errorActionWrapperWithId(types_2.ActionType.UNSUPPORTED_OPERATION, action, message || 'The action type is unsupported');
}
exports.unsupportedOperationAction = unsupportedOperationAction;
function persistenceAction(action, message) {
    return errorActionWrapperWithId(types_2.ActionType.PERSISTENCE, action, message || 'Action cannot be persisted on server');
}
exports.persistenceAction = persistenceAction;
function networkAction(action, message) {
    return errorActionWrapperWithId(types_2.ActionType.NETWORK, action, message || 'Network error');
}
exports.networkAction = networkAction;
function permissionAction(action, message) {
    return errorActionWrapperWithId(types_2.ActionType.PERMISSION, action, message || 'Action is not permitted');
}
exports.permissionAction = permissionAction;
function isErrorEventName(eventName) {
    var match = helper_1.findMatchInEnum(types_2.Action, eventName);
    return typeof match === 'string';
}
exports.isErrorEventName = isErrorEventName;
var AppBridgeError = /** @class */ (function () {
    function AppBridgeError(message) {
        this.name = 'AppBridgeError';
        this.message = message;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        }
        else {
            this.stack = new Error(this.message).stack;
        }
    }
    return AppBridgeError;
}());
exports.AppBridgeError = AppBridgeError;
AppBridgeError.prototype = Object.create(Error.prototype);
function fromAction(message, type, action) {
    var errorMessage = message ? type + ": " + message : type;
    var error = new AppBridgeError(errorMessage);
    error.action = action;
    error.type = type;
    return error;
}
exports.fromAction = fromAction;
function throwError() {
    var type = arguments[0];
    var message;
    var action;
    if (typeof arguments[1] === 'string') {
        message = arguments[1];
    }
    else {
        action = arguments[1];
        message = arguments[2] || '';
    }
    throw fromAction(message, type, action);
}
exports.throwError = throwError;
