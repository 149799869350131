"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
*/
var noop = function () { return undefined; };
var supportsPassive = null;
function supportsEventListenerPassiveOption() {
    if (supportsPassive !== null) {
        return supportsPassive;
    }
    try {
        var opts = Object.defineProperty({}, 'passive', {
            get: function () { supportsPassive = true; },
        });
        document.addEventListener('test', noop, opts);
    }
    catch (error) {
        supportsPassive = false;
    }
    document.removeEventListener('test', noop);
    return supportsPassive;
}
exports.supportsEventListenerPassiveOption = supportsEventListenerPassiveOption;
var supportsOnce = null;
function supportsEventListenerOnceOption() {
    if (supportsOnce !== null) {
        return supportsOnce;
    }
    try {
        var opts = Object.defineProperty({}, 'once', {
            get: function () { supportsOnce = true; },
        });
        document.addEventListener('test', noop, opts);
    }
    catch (error) {
        supportsOnce = false;
    }
    document.removeEventListener('test', noop);
    return supportsOnce;
}
exports.supportsEventListenerOnceOption = supportsEventListenerOnceOption;
