"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var buttonHelper_1 = require("../buttonHelper");
var helper_1 = require("../helper");
var types_1 = require("../types");
var types_2 = require("./types");
function update(group, component, props) {
    return buttonActionWrapper(group, component, types_2.Action.UPDATE, props);
}
exports.update = update;
function isGroupedButton(options) {
    var castOptions = options;
    return castOptions.buttons && castOptions.buttons.length > 0 && castOptions.label !== undefined;
}
exports.isGroupedButton = isGroupedButton;
function isGroupedButtonPayload(payload) {
    var castOptions = payload;
    return (Array.isArray(castOptions.buttons) &&
        typeof castOptions.id === 'string' &&
        typeof castOptions.label === 'string');
}
exports.isGroupedButtonPayload = isGroupedButtonPayload;
var ButtonGroup = /** @class */ (function (_super) {
    __extends(ButtonGroup, _super);
    function ButtonGroup(app, options) {
        var _this = _super.call(this, app, types_1.ComponentType.ButtonGroup, types_1.Group.ButtonGroup) || this;
        _this.disabled = false;
        _this.buttonsOptions = [];
        _this.buttons = [];
        _this.set(options, false);
        return _this;
    }
    Object.defineProperty(ButtonGroup.prototype, "options", {
        get: function () {
            return {
                buttons: this.buttonsOptions,
                disabled: this.disabled,
                label: this.label,
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonGroup.prototype, "payload", {
        get: function () {
            return __assign({}, this.options, { buttons: this.buttons, id: this.id });
        },
        enumerable: true,
        configurable: true
    });
    ButtonGroup.prototype.set = function (options, shouldUpdate) {
        if (shouldUpdate === void 0) { shouldUpdate = true; }
        var mergedOptions = helper_1.getMergedProps(this.options, options);
        var label = mergedOptions.label, disabled = mergedOptions.disabled, buttons = mergedOptions.buttons;
        this.label = label;
        this.disabled = !!disabled;
        this.buttons = this.getButtons(buttons);
        if (shouldUpdate) {
            this.dispatch(types_2.Action.UPDATE);
        }
        return this;
    };
    ButtonGroup.prototype.dispatch = function (action) {
        switch (action) {
            case types_2.Action.UPDATE:
                var updateAction = update(this.group, this.component, this.payload);
                this.app.dispatch(updateAction);
                break;
        }
        return this;
    };
    ButtonGroup.prototype.updateButtons = function (newPayload) {
        if (!this.buttons || this.buttons.length === 0) {
            return;
        }
        var updated;
        for (var _i = 0, _a = this.buttons; _i < _a.length; _i++) {
            var action = _a[_i];
            updated = helper_1.updateActionFromPayload(action, newPayload);
            if (updated) {
                break;
            }
        }
        if (updated) {
            this.dispatch(types_2.Action.UPDATE);
        }
    };
    ButtonGroup.prototype.getSingleButton = function (button) {
        return buttonHelper_1.getSingleButton(this, button, this.subgroups, this.updateButtons);
    };
    ButtonGroup.prototype.getButtons = function (buttonOptions) {
        var _this = this;
        var buttons = [];
        if (!buttonOptions) {
            return [];
        }
        buttonOptions.forEach(function (button) {
            var singleButton = buttonHelper_1.getSingleButton(_this, button, _this.subgroups, _this.updateButtons);
            buttons.push(singleButton);
        });
        this.buttonsOptions = buttonOptions;
        return buttons;
    };
    return ButtonGroup;
}(helper_1.ActionSetWithChildren));
exports.ButtonGroup = ButtonGroup;
function create(app, options) {
    return new ButtonGroup(app, options);
}
exports.create = create;
function buttonActionWrapper(group, component, eventName, props, payload) {
    var id = component.id;
    var label = props.label;
    var action = helper_1.getEventNameSpace(group, eventName, component);
    var buttonPayload = __assign({ id: id,
        label: label }, props, { payload: payload });
    return helper_1.actionWrapper({ type: action, group: group, payload: buttonPayload });
}
