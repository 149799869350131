"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Action;
(function (Action) {
    Action["OPEN"] = "OPEN";
    Action["SELECT"] = "SELECT";
    Action["CLOSE"] = "CLOSE";
    Action["UPDATE"] = "UPDATE";
    Action["CANCEL"] = "CANCEL";
})(Action = exports.Action || (exports.Action = {}));
var ActionType;
(function (ActionType) {
    ActionType["OPEN"] = "APP::RESOURCE_PICKER::OPEN";
    ActionType["SELECT"] = "APP::RESOURCE_PICKER::SELECT";
    ActionType["CLOSE"] = "APP::RESOURCE_PICKER::CLOSE";
    ActionType["UPDATE"] = "APP::RESOURCE_PICKER::UPDATE";
    ActionType["CANCEL"] = "APP::RESOURCE_PICKER::CANCEL";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
var CollectionSortOrder;
(function (CollectionSortOrder) {
    CollectionSortOrder["Manual"] = "MANUAL";
    CollectionSortOrder["BestSelling"] = "BEST_SELLING";
    CollectionSortOrder["AlphaAsc"] = "ALPHA_ASC";
    CollectionSortOrder["AlphaDesc"] = "ALPHA_DESC";
    CollectionSortOrder["PriceDesc"] = "PRICE_DESC";
    CollectionSortOrder["PriceAsc"] = "PRICE_ASC";
    CollectionSortOrder["CreatedDesc"] = "CREATED_DESC";
    CollectionSortOrder["Created"] = "CREATED";
})(CollectionSortOrder = exports.CollectionSortOrder || (exports.CollectionSortOrder = {}));
var FulfillmentServiceType;
(function (FulfillmentServiceType) {
    FulfillmentServiceType["GiftCard"] = "GIFT_CARD";
    FulfillmentServiceType["Manual"] = "MANUAL";
    FulfillmentServiceType["ThirdParty"] = "THIRD_PARTY";
})(FulfillmentServiceType = exports.FulfillmentServiceType || (exports.FulfillmentServiceType = {}));
var WeightUnit;
(function (WeightUnit) {
    WeightUnit["Kilograms"] = "KILOGRAMS";
    WeightUnit["Grams"] = "GRAMS";
    WeightUnit["Pounds"] = "POUNDS";
    WeightUnit["Ounces"] = "OUNCES";
})(WeightUnit = exports.WeightUnit || (exports.WeightUnit = {}));
var ProductVariantInventoryPolicy;
(function (ProductVariantInventoryPolicy) {
    ProductVariantInventoryPolicy["Deny"] = "DENY";
    ProductVariantInventoryPolicy["Continue"] = "CONTINUE";
})(ProductVariantInventoryPolicy = exports.ProductVariantInventoryPolicy || (exports.ProductVariantInventoryPolicy = {}));
var ProductVariantInventoryManagement;
(function (ProductVariantInventoryManagement) {
    ProductVariantInventoryManagement["Shopify"] = "SHOPIFY";
    ProductVariantInventoryManagement["NotManaged"] = "NOT_MANAGED";
    ProductVariantInventoryManagement["FulfillmentService"] = "FULFILLMENT_SERVICE";
})(ProductVariantInventoryManagement = exports.ProductVariantInventoryManagement || (exports.ProductVariantInventoryManagement = {}));
var ResourceType;
(function (ResourceType) {
    ResourceType["Product"] = "product";
    ResourceType["ProductVariant"] = "variant";
    ResourceType["Collection"] = "collection";
})(ResourceType = exports.ResourceType || (exports.ResourceType = {}));
var ActionVerb;
(function (ActionVerb) {
    ActionVerb["Add"] = "add";
    ActionVerb["Select"] = "select";
})(ActionVerb = exports.ActionVerb || (exports.ActionVerb = {}));
