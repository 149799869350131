"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var helper_1 = require("../helper");
var types_1 = require("../types");
var Action;
(function (Action) {
    Action["CLOSE"] = "CLOSE";
})(Action = exports.Action || (exports.Action = {}));
/**
 * Pos action type enum
 * @remarks includes the action prefix and group
 *
 */
var ActionType;
(function (ActionType) {
    ActionType["CLOSE"] = "APP::POS::CLOSE";
    ActionType["LOCATION_UPDATE"] = "APP::POS::LOCATION::UPDATE";
    ActionType["USER_UPDATE"] = "APP::POS::USER::UPDATE";
    ActionType["DEVICE_UPDATE"] = "APP::POS::DEVICE::UPDATE";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
function close() {
    return helper_1.actionWrapper({
        group: types_1.Group.Pos,
        type: ActionType.CLOSE,
    });
}
exports.close = close;
var Pos = /** @class */ (function (_super) {
    __extends(Pos, _super);
    function Pos(app) {
        return _super.call(this, app, types_1.Group.Pos, types_1.Group.Pos) || this;
    }
    Pos.prototype.dispatch = function (action) {
        switch (action) {
            case ActionType.CLOSE:
                this.app.dispatch(close());
                break;
        }
        return this;
    };
    return Pos;
}(helper_1.ActionSet));
exports.Pos = Pos;
function create(app) {
    return new Pos(app);
}
exports.create = create;
