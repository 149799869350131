"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Rect = /** @class */ (function () {
    function Rect(_a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.top, top = _c === void 0 ? 0 : _c, _d = _b.left, left = _d === void 0 ? 0 : _d, _e = _b.width, width = _e === void 0 ? 0 : _e, _f = _b.height, height = _f === void 0 ? 0 : _f;
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
    }
    Object.defineProperty(Rect, "zero", {
        get: function () {
            return new Rect();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Rect.prototype, "center", {
        get: function () {
            return {
                x: this.left + (this.width / 2),
                y: this.top + (this.height / 2),
            };
        },
        enumerable: true,
        configurable: true
    });
    return Rect;
}());
exports.Rect = Rect;
function getRectForNode(node) {
    if (!(node instanceof Element)) {
        return new Rect({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }
    var rect = node.getBoundingClientRect();
    return new Rect({
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
    });
}
exports.getRectForNode = getRectForNode;
