module.exports = {
  colorPurpleText: 'rgb(80, 73, 90)',
  colorPurpleDarker: 'rgb(35, 0, 81)',
  colorPurpleDark: 'rgb(80, 36, 143)',
  colorPurple: 'rgb(156, 106, 222)',
  colorPurpleLight: 'rgb(227, 208, 255)',
  colorPurpleLighter: 'rgb(246, 240, 253)',
  colorIndigoText: 'rgb(62, 65, 85)',
  colorIndigoDarker: 'rgb(0, 6, 57)',
  colorIndigoDark: 'rgb(32, 46, 120)',
  colorIndigo: 'rgb(92, 106, 196)',
  colorIndigoLight: 'rgb(179, 188, 245)',
  colorIndigoLighter: 'rgb(244, 245, 250)',
  colorBlueText: 'rgb(62, 78, 87)',
  colorBlueDarker: 'rgb(0, 20, 41)',
  colorBlueDark: 'rgb(8, 78, 138)',
  colorBlue: 'rgb(0, 111, 187)',
  colorBlueLight: 'rgb(180, 225, 250)',
  colorBlueLighter: 'rgb(235, 245, 250)',
  colorTealText: 'rgb(64, 83, 82)',
  colorTealDarker: 'rgb(0, 49, 53)',
  colorTealDark: 'rgb(0, 132, 142)',
  colorTeal: 'rgb(71, 193, 191)',
  colorTealLight: 'rgb(183, 236, 236)',
  colorTealLighter: 'rgb(224, 245, 245)',
  colorGreenText: 'rgb(65, 79, 62)',
  colorGreenDarker: 'rgb(23, 54, 48)',
  colorGreenDark: 'rgb(16, 128, 67)',
  colorGreen: 'rgb(80, 184, 60)',
  colorGreenLight: 'rgb(187, 229, 179)',
  colorGreenLighter: 'rgb(227, 241, 223)',
  colorYellowText: 'rgb(89, 81, 48)',
  colorYellowDarker: 'rgb(87, 59, 0)',
  colorYellowDark: 'rgb(138, 97, 22)',
  colorYellow: 'rgb(238, 194, 0)',
  colorYellowLight: 'rgb(255, 234, 138)',
  colorYellowLighter: 'rgb(252, 241, 205)',
  colorOrangeText: 'rgb(89, 68, 48)',
  colorOrangeDarker: 'rgb(74, 21, 4)',
  colorOrangeDark: 'rgb(192, 87, 23)',
  colorOrange: 'rgb(244, 147, 66)',
  colorOrangeLight: 'rgb(255, 197, 139)',
  colorOrangeLighter: 'rgb(252, 235, 219)',
  colorRedText: 'rgb(88, 60, 53)',
  colorRedDarker: 'rgb(51, 1, 1)',
  colorRedDark: 'rgb(191, 7, 17)',
  colorRed: 'rgb(222, 54, 24)',
  colorRedLight: 'rgb(254, 173, 154)',
  colorRedLighter: 'rgb(251, 234, 229)',
  colorInk: 'rgb(33, 43, 54)',
  colorInkLight: 'rgb(69, 79, 91)',
  colorInkLighter: 'rgb(99, 115, 129)',
  colorInkLightest: 'rgb(145, 158, 171)',
  colorSkyDark: 'rgb(196, 205, 213)',
  colorSky: 'rgb(223, 227, 232)',
  colorSkyLight: 'rgb(244, 246, 248)',
  colorSkyLighter: 'rgb(249, 250, 251)',
  colorBlack: 'rgb(0, 0, 0)',
  colorWhite: 'rgb(255, 255, 255)',
  durationNone: 0,
  durationFast: 100,
  durationBase: 200,
  durationSlow: 300,
  durationSlower: 400,
  durationSlowest: 500,
  spacingNone: 0,
  spacingExtraTight: '4px',
  spacingTight: '8px',
  spacingBaseTight: '12px',
  spacingBase: '16px',
  spacingLoose: '20px',
  spacingExtraLoose: '32px',
  fontStackBase:
    "-apple-system, BlinkMacSystemFont, 'San Francisco', Roboto, 'Segoe UI', 'Helvetica Neue', sans-serif",
  fontStackMonospace: "Monaco, Consolas, 'Lucida Console', monospace",
};
